/*
@tailwind base;

@tailwind components;
*/

.bg-red {
  background-color: #F44336
}

.bg-orange {
  background-color: #FF9800
}

.bg-green {
  background-color: #4CAF50
}

.bg-blue {
  background-color: #2196F3
}

.bg-pink {
  background-color: #E91E63
}

.hover\:bg-transparent:hover {
  background-color: transparent
}

.focus\:bg-transparent:focus {
  background-color: transparent
}

.rounded-8 {
  border-radius: .8rem
}

.rounded {
  border-radius: .4rem
}

.rounded-lg {
  border-radius: .8rem
}

.border-0 {
  border-width: 0
}

.border-1 {
  border-width: 1px
}

.border-4 {
  border-width: 4px
}

.border {
  border-width: 1px
}

.border-b-1 {
  border-bottom-width: 1px
}

.cursor-default {
  cursor: default
}

.cursor-pointer {
  cursor: pointer
}

.block {
  display: block
}

.inline-block {
  display: inline-block
}

.inline {
  display: inline
}

.flex {
  display: -webkit-flex;
  display: flex
}

.inline-flex {
  display: -webkit-inline-flex;
  display: inline-flex
}

.table {
  display: table
}

.hidden {
  display: none
}

.flex-row {
  -webkit-flex-direction: row;
          flex-direction: row
}

.flex-row-reverse {
  -webkit-flex-direction: row-reverse;
          flex-direction: row-reverse
}

.flex-col {
  -webkit-flex-direction: column;
          flex-direction: column
}

.flex-wrap {
  -webkit-flex-wrap: wrap;
          flex-wrap: wrap
}

.items-start {
  -webkit-align-items: flex-start;
          align-items: flex-start
}

.items-end {
  -webkit-align-items: flex-end;
          align-items: flex-end
}

.items-center {
  -webkit-align-items: center;
          align-items: center
}

.justify-end {
  -webkit-justify-content: flex-end;
          justify-content: flex-end
}

.justify-center {
  -webkit-justify-content: center;
          justify-content: center
}

.justify-between {
  -webkit-justify-content: space-between;
          justify-content: space-between
}

.flex-1 {
  -webkit-flex: 1 1 0%;
          flex: 1 1 0%
}

.flex-auto {
  -webkit-flex: 1 1 auto;
          flex: 1 1 auto
}

.flex-grow-0 {
  -webkit-flex-grow: 0;
          flex-grow: 0
}

.flex-grow {
  -webkit-flex-grow: 1;
          flex-grow: 1
}

.flex-shrink-0 {
  -webkit-flex-shrink: 0;
          flex-shrink: 0
}

.flex-shrink {
  -webkit-flex-shrink: 1;
          flex-shrink: 1
}

.font-600 {
  font-weight: 600
}

.font-thin {
  font-weight: 200
}

.font-light {
  font-weight: 300
}

.font-medium {
  font-weight: 500
}

.font-bold {
  font-weight: 700
}

.h-8 {
  height: 0.8rem
}

.h-16 {
  height: 1.6rem
}

.h-40 {
  height: 4rem
}

.h-48 {
  height: 4.8rem
}

.h-56 {
  height: 5.6rem
}

.h-64 {
  height: 6.4rem
}

.h-72 {
  height: 7.2rem
}

.h-96 {
  height: 9.6rem
}

.h-128 {
  height: 12.8rem
}

.h-full {
  height: 100%
}

.h-screen {
  height: 100vh
}

.leading-none {
  line-height: 1
}

.m-8 {
  margin: 0.8rem
}

.m-12 {
  margin: 1.2rem
}

.m-16 {
  margin: 1.6rem
}

.m-24 {
  margin: 2.4rem
}

.m-32 {
  margin: 3.2rem
}

.mx-4 {
  margin-left: 0.4rem;
  margin-right: 0.4rem
}

.my-8 {
  margin-top: 0.8rem;
  margin-bottom: 0.8rem
}

.mx-8 {
  margin-left: 0.8rem;
  margin-right: 0.8rem
}

.mx-12 {
  margin-left: 1.2rem;
  margin-right: 1.2rem
}

.my-16 {
  margin-top: 1.6rem;
  margin-bottom: 1.6rem
}

.mx-16 {
  margin-left: 1.6rem;
  margin-right: 1.6rem
}

.my-24 {
  margin-top: 2.4rem;
  margin-bottom: 2.4rem
}

.mx-24 {
  margin-left: 2.4rem;
  margin-right: 2.4rem
}

.my-48 {
  margin-top: 4.8rem;
  margin-bottom: 4.8rem
}

.mx-auto {
  margin-left: auto;
  margin-right: auto
}

.-mx-12 {
  margin-left: -1.2rem;
  margin-right: -1.2rem
}

.mt-0 {
  margin-top: 0
}

.mb-0 {
  margin-bottom: 0
}

.mb-4 {
  margin-bottom: 0.4rem
}

.mt-8 {
  margin-top: 0.8rem
}

.mt-12 {
  margin-top: 1.2rem
}

.mr-12 {
  margin-right: 1.2rem
}

.mb-12 {
  margin-bottom: 1.2rem
}

.ml-12 {
  margin-left: 1.2rem
}

.mt-16 {
  margin-top: 1.6rem
}

.mb-16 {
  margin-bottom: 1.6rem
}

.mt-24 {
  margin-top: 2.4rem
}

.mr-24 {
  margin-right: 2.4rem
}

.mb-24 {
  margin-bottom: 2.4rem
}

.ml-24 {
  margin-left: 2.4rem
}

.mr-28 {
  margin-right: 2.8rem
}

.mb-32 {
  margin-bottom: 3.2rem
}

.mt-48 {
  margin-top: 4.8rem
}

.mb-48 {
  margin-bottom: 4.8rem
}

.mb-96 {
  margin-bottom: 9.6rem
}

[dir='ltr'] .ltr\:ml-4,[dir='ltr'].ltr\:ml-4 {
  margin-left: 0.4rem
}

[dir='ltr'] .ltr\:ml-8,[dir='ltr'].ltr\:ml-8 {
  margin-left: 0.8rem
}

[dir='ltr'] .ltr\:mr-48,[dir='ltr'].ltr\:mr-48 {
  margin-right: 4.8rem
}

[dir='rtl'] .rtl\:mr-4,[dir='rtl'].rtl\:mr-4 {
  margin-right: 0.4rem
}

[dir='rtl'] .rtl\:mr-8,[dir='rtl'].rtl\:mr-8 {
  margin-right: 0.8rem
}

[dir='rtl'] .rtl\:ml-48,[dir='rtl'].rtl\:ml-48 {
  margin-left: 4.8rem
}

.max-w-96 {
  max-width: 9.6rem
}

.max-w-200 {
  max-width: 20rem
}

.max-w-224 {
  max-width: 22.4rem
}

.max-w-320 {
  max-width: 32rem
}

.max-w-384 {
  max-width: 38.4rem
}

.max-w-400 {
  max-width: 40rem
}

.max-w-512 {
  max-width: 51.2rem
}

.max-w-2xl {
  max-width: 112rem
}

.max-w-full {
  max-width: 100%
}

.min-h-64 {
  min-height: 6.4rem
}

.min-h-72 {
  min-height: 7.2rem
}

.min-h-128 {
  min-height: 12.8rem
}

.min-h-px {
  min-height: 1px
}

.min-h-screen {
  min-height: 100vh
}

.min-w-20 {
  min-width: 2rem
}

.min-w-40 {
  min-width: 4rem
}

.min-w-48 {
  min-width: 4.8rem
}

.min-w-64 {
  min-width: 6.4rem
}

.min-w-200 {
  min-width: 20rem
}

.min-w-288 {
  min-width: 28.8rem
}

.min-w-320 {
  min-width: 32rem
}

.min-w-400 {
  min-width: 40rem
}

.min-w-full {
  min-width: 100%
}

.opacity-50 {
  opacity: 0.5
}

.overflow-auto {
  overflow: auto
}

.overflow-hidden {
  overflow: hidden
}

.overflow-y-auto {
  overflow-y: auto
}

.p-0 {
  padding: 0
}

.p-4 {
  padding: 0.4rem
}

.p-8 {
  padding: 0.8rem
}

.p-12 {
  padding: 1.2rem
}

.p-16 {
  padding: 1.6rem
}

.p-24 {
  padding: 2.4rem
}

.p-32 {
  padding: 3.2rem
}

.p-96 {
  padding: 9.6rem
}

.py-0 {
  padding-top: 0;
  padding-bottom: 0
}

.px-0 {
  padding-left: 0;
  padding-right: 0
}

.py-4 {
  padding-top: 0.4rem;
  padding-bottom: 0.4rem
}

.px-4 {
  padding-left: 0.4rem;
  padding-right: 0.4rem
}

.py-8 {
  padding-top: 0.8rem;
  padding-bottom: 0.8rem
}

.px-8 {
  padding-left: 0.8rem;
  padding-right: 0.8rem
}

.py-12 {
  padding-top: 1.2rem;
  padding-bottom: 1.2rem
}

.px-12 {
  padding-left: 1.2rem;
  padding-right: 1.2rem
}

.py-16 {
  padding-top: 1.6rem;
  padding-bottom: 1.6rem
}

.px-16 {
  padding-left: 1.6rem;
  padding-right: 1.6rem
}

.px-24 {
  padding-left: 2.4rem;
  padding-right: 2.4rem
}

.pt-2 {
  padding-top: 0.2rem
}

.pt-8 {
  padding-top: 0.8rem
}

.pb-8 {
  padding-bottom: 0.8rem
}

.pb-12 {
  padding-bottom: 1.2rem
}

.pt-16 {
  padding-top: 1.6rem
}

.pb-16 {
  padding-bottom: 1.6rem
}

.pl-16 {
  padding-left: 1.6rem
}

.pt-20 {
  padding-top: 2rem
}

.pb-20 {
  padding-bottom: 2rem
}

.pt-24 {
  padding-top: 2.4rem
}

.pb-24 {
  padding-bottom: 2.4rem
}

.pl-24 {
  padding-left: 2.4rem
}

.pt-32 {
  padding-top: 3.2rem
}

.pb-40 {
  padding-bottom: 4rem
}

.pl-40 {
  padding-left: 4rem
}

.pl-56 {
  padding-left: 5.6rem
}

.pb-64 {
  padding-bottom: 6.4rem
}

.pl-72 {
  padding-left: 7.2rem
}

.pb-80 {
  padding-bottom: 8rem
}

.pl-80 {
  padding-left: 8rem
}

[dir='ltr'] .ltr\:pl-40,[dir='ltr'].ltr\:pl-40 {
  padding-left: 4rem
}

[dir='ltr'] .ltr\:pr-48,[dir='ltr'].ltr\:pr-48 {
  padding-right: 4.8rem
}

[dir='rtl'] .rtl\:pr-40,[dir='rtl'].rtl\:pr-40 {
  padding-right: 4rem
}

[dir='rtl'] .rtl\:pl-48,[dir='rtl'].rtl\:pl-48 {
  padding-left: 4.8rem
}

.pointer-events-none {
  pointer-events: none
}

.static {
  position: static
}

.fixed {
  position: fixed
}

.absolute {
  position: absolute
}

.relative {
  position: relative
}

.top-0 {
  top: 0
}

.right-0 {
  right: 0
}

.bottom-0 {
  bottom: 0
}

.left-0 {
  left: 0
}

[dir='ltr'] .ltr\:right-0,[dir='ltr'].ltr\:right-0 {
  right: 0
}

[dir='rtl'] .rtl\:left-0,[dir='rtl'].rtl\:left-0 {
  left: 0
}

.shadow-none {
  box-shadow: none
}

.text-left {
  text-align: left
}

.text-center {
  text-align: center
}

.text-white {
  color: #FFF
}

.text-red {
  color: #F44336
}

.text-green {
  color: #4CAF50
}

.text-blue {
  color: #2196F3
}

.text-11 {
  font-size: 1.1rem
}

.text-12 {
  font-size: 1.2rem
}

.text-13 {
  font-size: 1.3rem
}

.text-14 {
  font-size: 1.4rem
}

.text-16 {
  font-size: 1.6rem
}

.text-20 {
  font-size: 2rem
}

.text-24 {
  font-size: 2.4rem
}

.text-32 {
  font-size: 3.2rem
}

.text-128 {
  font-size: 12.8rem
}

.italic {
  font-style: italic
}

.uppercase {
  text-transform: uppercase
}

.capitalize {
  text-transform: capitalize
}

.normal-case {
  text-transform: none
}

.underline {
  text-decoration: underline
}

.visible {
  visibility: visible
}

.whitespace-no-wrap {
  white-space: nowrap
}

.truncate {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap
}

.w-16 {
  width: 1.6rem
}

.w-24 {
  width: 2.4rem
}

.w-32 {
  width: 3.2rem
}

.w-40 {
  width: 4rem
}

.w-48 {
  width: 4.8rem
}

.w-64 {
  width: 6.4rem
}

.w-96 {
  width: 9.6rem
}

.w-128 {
  width: 12.8rem
}

.w-224 {
  width: 22.4rem
}

.w-256 {
  width: 25.6rem
}

.w-xs {
  width: 32rem
}

.w-lg {
  width: 80rem
}

.w-1\/2 {
  width: 50%
}

.w-1\/3 {
  width: 33.333333%
}

.w-2\/3 {
  width: 66.666667%
}

.w-1\/4 {
  width: 25%
}

.w-full {
  width: 100%
}

.z-0 {
  z-index: 0
}

.z-10 {
  z-index: 10
}

.z-9999 {
  z-index: 9999
}

@media (min-width: 600px) {

  .sm\:rounded-16 {
    border-radius: 1.6rem
  }

  .sm\:flex {
    display: -webkit-flex;
    display: flex
  }

  .sm\:h-136 {
    height: 13.6rem
  }

  .sm\:min-h-136 {
    min-height: 13.6rem
  }

  .sm\:p-24 {
    padding: 2.4rem
  }

  .sm\:p-32 {
    padding: 3.2rem
  }

  .sm\:px-12 {
    padding-left: 1.2rem;
    padding-right: 1.2rem
  }

  .sm\:pb-80 {
    padding-bottom: 8rem
  }

  .sm\:w-224 {
    width: 22.4rem
  }
}

@media (min-width: 960px) {

  .md\:flex {
    display: -webkit-flex;
    display: flex
  }

  .md\:flex-row {
    -webkit-flex-direction: row;
            flex-direction: row
  }

  .md\:items-start {
    -webkit-align-items: flex-start;
            align-items: flex-start
  }

  .md\:flex-1 {
    -webkit-flex: 1 1 0%;
            flex: 1 1 0%
  }

  .md\:flex-shrink-0 {
    -webkit-flex-shrink: 0;
            flex-shrink: 0
  }

  .md\:m-0 {
    margin: 0
  }

  .md\:overflow-hidden {
    overflow: hidden
  }

  .md\:p-0 {
    padding: 0
  }

  .md\:p-48 {
    padding: 4.8rem
  }

  .md\:p-128 {
    padding: 12.8rem
  }

  .md\:pt-128 {
    padding-top: 12.8rem
  }

  .md\:text-left {
    text-align: left
  }

  .md\:w-full {
    width: 100%
  }
}

@media (min-width: 1280px) {

  .lg\:rounded-8 {
    border-radius: .8rem
  }

  .lg\:mx-24 {
    margin-left: 2.4rem;
    margin-right: 2.4rem
  }

  .lg\:p-24 {
    padding: 2.4rem
  }

  .lg\:px-24 {
    padding-left: 2.4rem;
    padding-right: 2.4rem
  }

  .lg\:pr-0 {
    padding-right: 0
  }

  [dir='ltr'] .lg\:ltr\:pl-0,[dir='ltr'].lg\:ltr\:pl-0 {
    padding-left: 0
  }

  [dir='ltr'] .lg\:ltr\:pr-4,[dir='ltr'].lg\:ltr\:pr-4 {
    padding-right: 0.4rem
  }

  [dir='rtl'] .lg\:rtl\:pr-0,[dir='rtl'].lg\:rtl\:pr-0 {
    padding-right: 0
  }

  [dir='rtl'] .lg\:rtl\:pl-4,[dir='rtl'].lg\:rtl\:pl-4 {
    padding-left: 0.4rem
  }

  .lg\:shadow-1 {
    box-shadow: 0px 1px 3px 0px rgba(0,0,0,0.2),0px 1px 1px 0px rgba(0,0,0,0.14),0px 2px 1px -1px rgba(0,0,0,0.12)
  }
}